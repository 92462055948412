import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
    {
        path: "/service",
        element: lazyLoad(React.lazy(() => import("@/views/service"))),
        meta: {
            requiresAuth: true,
            title: "客服中心",
        },
    },
] as Array<RouteObject>;