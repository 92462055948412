import { routesItems } from "..";

export const getRouteObjectByPathName = (
  pathname: string
): MayUndefined<RouteObject> => {
  //不deep

  const visitChild = (
    children: Array<RouteObject>
  ): MayUndefined<RouteObject> => {
    let route: MayUndefined<RouteObject> = undefined;
    for (let index = 0; index < children.length; index++) {
      if (route) break;
      const _ = children[index];
      if (_.children) {
        route = visitChild(_.children);
      }
      if (_.path === pathname) route = _;
    }
    return route;
  };
  const route = visitChild(routesItems);
  return route;
};
