import { ErrorOutline, Refresh } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"

const FallbackError: FC = () => {
    const linkTo: string = "/index";
    return <Box bgcolor="background.default" className="w-[100vw] h-[100vh] flex justify-center items-center">
        <div>
            <Typography component={"h5"} variant="h5" color="error">
                <ErrorOutline></ErrorOutline>出现错误
            </Typography>
            <Link to={linkTo} replace>
                <Typography component={"p"} variant="body1" color="text.primary">
                    点我刷新<Refresh/>
                </Typography>
            </Link>
        </div>
    </Box>
}

export default FallbackError;