import { FC, useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { AppBar, Box, Icon, IconButton, Paper, ThemeOptions, Toolbar, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import routesItems from "@/core/routes/routes";
import { getRouteObjectByPathName } from "@/core/routes/utils";
// import { AppBarProps } from "@/types";



const defaultLeading = (theme: ThemeOptions, action: VoidFunction) => {
    const idx: number = window?.history?.state?.idx || 0;
    const isLower = idx < 1;
    if (isLower) return <></>
    return <IconButton onClick={() => {
        action();
    }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
        {/* <ArrowBack style={{
            color: theme.palette?.text?.primary,
        }} /> */}
    </IconButton>;
}
const MyAppBar: FC<AppBarProps> = ({ titleCenter = "left", leading, actions, title }) => {
    const theme = useTheme();
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState(title);
    const pathname = location.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname && !title) {
            const route = getRouteObjectByPathName(pathname);
            if (route&&!route?.meta?.appBarDisabled) {
                setPageTitle(route?.meta?.title ?? '');
            }
        }
    }, [pathname]);

    if (pageTitle || leading || actions)
        return <Paper elevation={10}>
            <AppBar position="static" >
                <Toolbar sx={(theme) => ({
                    paddingLeft: 0,
                    paddingRight: 0,
                })}>
                    <div>
                        {leading || defaultLeading(theme, () => {
                            navigate(-1)
                        })}
                    </div>
                    <div className="flex-1 justify-center">
                        <Typography className="pl-2 text-dark-text" textAlign={titleCenter} variant="h6" component="h3" sx={{ flexGrow: 1 }}>
                            {pageTitle}
                        </Typography>
                    </div>
                    <div>
                        {
                            actions && <Box className="flex">
                                {
                                    actions.map(_ => <span key={""}>{_}</span>)
                                }
                            </Box>
                        }
                    </div>
                </Toolbar>
            </AppBar>
        </Paper>;
    return <></>
}

export default MyAppBar;