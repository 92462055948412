// import { BasePageOption } from "@/types";
import { Box } from "@mui/material";
import MyAppBar from "./components/app-bar";

export const BasePage: React.FC<BasePageOption> = ({ body, children, appBar, disableAppBar }) => {
    return <>
        <Box sx={
            {
                width: '100%',
                height: '100%',
            }
        }>
            <Box className="base-page-wrapper">
                {!disableAppBar && <MyAppBar {...appBar} />}
                {
                    (body || children) && <Box className="base-page-body-wrapper" bgcolor="secondary.light">
                        {body || children}
                    </Box>

                }{
                    (!body && !children) && <>
                        <div className="w-full h-full bg-background dark:bg-dark-background"></div>
                    </>
                }
            </Box>
        </Box>
    </>
}