import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
    {
        path: "/address",
        element: lazyLoad(React.lazy(() => import("@/views/address"))),
        meta: {
            requiresAuth: true,
            title: "收货地址管理",
        },
    },
    {
        path: "/address/add-address",
        element: lazyLoad(React.lazy(() => import("@/views/address/add-address"))),
        meta: {
            requiresAuth: true,
            title: "新增收货地址",
        },
    },
    {
        path: "/address/edit-address",
        element: lazyLoad(React.lazy(() => import("@/views/address/edit-address"))),
        meta: {
            requiresAuth: true,
            title: "修改收货地址",
        },
    },
] as Array<RouteObject>;
