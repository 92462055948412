
import { Button, Chip, Icon, IconButton, Typography } from "@mui/material";
import { BasePage } from "../base"
import { FC, useContext, useEffect, useRef, useState } from "react";
import { LockClock } from "@mui/icons-material";
import { GlobalComponentsContext } from "@/core/contexts";
import ProductList from "@/common/components/product-list/product-list";
import { useNavigate } from "react-router";
import { storageUtils } from "@/core/utils";
import { useDialog } from "@/hooks/notification";


type SearchBoxProps = {
    defaultValue: string,
    disabled: boolean,
    handleSearch(text?: string): void,
    onInput(text?: string): void,
}

export const SearchBox: FC<Partial<SearchBoxProps>> = ({ defaultValue,
    disabled,
    handleSearch,
    onInput }) => {
    const value = useRef<string>(defaultValue ?? '');
    const [defaultTextValue, setDefaultTextValue] = useState(defaultValue ?? "");
    const handleOnInput = (e: any) => {
        value.current = e.target.value;
        onInput?.(value.current);
        setDefaultTextValue(value.current);
    }
    const handleClickSearch = () => {
        handleSearch?.(value.current);
    }
    window.onkeydown = (e: any) => {
        if (e === "Enter") {
            handleSearch?.(value.current);
        }
    }

    useEffect(() => {
        setDefaultTextValue(defaultValue ?? "");
    }, [defaultValue]);
    return <div className="flex flex-row justify-between bg-white rounded-[10rem] p-1">
        <div className="flex-1">
            <input value={defaultTextValue} onInput={handleOnInput} className="text-text text-sm p-2 pl-4 bg-transparent outline-none focus:outline-none" type="text" placeholder="输入药品名称" />
        </div>
        <Button disabled={disabled} size="small" onClick={handleClickSearch} sx={(theme) => ({
            borderRadius: '10rem',
            backgroundColor: '#00be46'
        })} className=" rounded-full bg-primary">
            <Typography color={"white"}>
                搜索
            </Typography>
        </Button>
    </div>
}


const Search: FC = () => {
    const SEARCH_HISTORY_KEY = "SEARCH_HISTORY";
    const [searchHistoryList, setSearchHistoryList] = useState<Array<string>>([]);
    const navigate = useNavigate();
    const dialog = useDialog();
    const fetchSearchData = (value: string) => {
        navigate("/search/result?searchText=" + value);
    }

    const handleClickChip = (_?: string) => {
        if (!_) return;
        const history = storageUtils.getItem(SEARCH_HISTORY_KEY);
        if (history) {
            const historyArr: Array<string> = JSON.parse(history);
            if (!historyArr.find(text => text === _)) {
                storageUtils.addItem(SEARCH_HISTORY_KEY, JSON.stringify([...historyArr, _]));
            }
        } else {
            storageUtils.addItem(SEARCH_HISTORY_KEY, JSON.stringify([_]));
        }

        fetchSearchData(_);
    }
    const handleLoadHistory = () => {
        const history = storageUtils.getItem(SEARCH_HISTORY_KEY);
        if (history) {
            setSearchHistoryList(JSON.parse(history));
        }
    }

    useEffect(() => {
        handleLoadHistory();
    }, []);

    const handleDeleteHistory = () => {
        dialog.showDialog({
            title: "确认删除历史记录吗？",
            onConfirm: () => {
                storageUtils.removeItem(SEARCH_HISTORY_KEY);
                setSearchHistoryList([]);
            }
        });
    }

    return <BasePage appBar={{
        title: <div className="pr-2">
            <SearchBox handleSearch={(value) => { handleClickChip(value) }} />
        </div>,
    }}>
        <div className="p-2 w-full h-full">
            {searchHistoryList.length !== 0 && <div className="flex justify-between mt-6">
                <Typography variant="h6" color="text.primary">搜索历史</Typography>
                <div>

                    <div onClick={handleDeleteHistory}>
                        <IconButton>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                        </IconButton>
                    </div>

                </div>
            </div>}
            <div className="mt-2 gap-2 flex flex-row flex-wrap justify-start">
                {
                    searchHistoryList.map((_, ndx) => <Chip onClick={() => { handleClickChip(_) }} label={_} key={ndx} />)
                }
            </div>
            {/* <div className="py-4 flex gap-2 w-full justify-center items-center">
                <img src="/static/images/other/ip.png" alt="ip" className="w-[1.5rem] h-[1.5rem]" />
                <Typography variant="h6" color="text.primary">更多好药</Typography>
            </div>
            <ProductList /> */}
        </div>
    </BasePage>
}

export default Search;