import { deBounce, throttle } from "@/core/utils";
import { debounce } from "@mui/material";
import { FC, ReactNode, useEffect, useRef, useState } from "react";


interface ScrollViewController {
    onScrollToTop(): void;
    onScrollToBottom(): void;
    onScrollToLeft(): void;
    onScrollToRight(): void;
    onScroll(delta: number): void;
}


class SimpleScrollController implements ScrollViewController {
    onScroll(detail: number): void {
        throw new Error("Method not implemented.");
    }
    onScrollToTop(): void {
        throw new Error("Method not implemented.");
    }
    onScrollToBottom(): void {
        throw new Error("Method not implemented.");
    }
    onScrollToLeft(): void {
        throw new Error("Method not implemented.");
    }
    onScrollToRight(): void {
        throw new Error("Method not implemented.");
    }
}


const useResizeObserver = (callback: (entry: ResizeObserverEntry) => void) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                callback(entry);
            });
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [callback]);

    return ref;
};



type ScrollViewOption = {
    children: ReactNode,
    axis?: "Vertical" | "Horizon";
    className?: string,
    toBeforeScroll?: boolean
}

const ScrollView: FC<ScrollViewOption & Partial<SimpleScrollController>> = ({ children, axis = "Vertical", onScrollToBottom, onScrollToTop, className = '', onScroll, toBeforeScroll }) => {
    const [dom, setDom] = useState<HTMLDivElement | null>();
    const beforeScrollHeight = useRef<number>(0);
    const handleOnScroll = () => {
        if (dom) {

            const contentScrollTop = dom.scrollTop; //滚动条距离顶部
            const clientHeight = dom.clientHeight; //可视区域
            const scrollHeight = dom.scrollHeight; //滚动条内容的总高度
            onScroll?.(contentScrollTop);
            if (contentScrollTop + clientHeight >= (scrollHeight-10)) {
                beforeScrollHeight.current = scrollHeight;
                onScrollToBottom?.();
            }
            if (contentScrollTop <= 0) {
                beforeScrollHeight.current = scrollHeight;
                onScrollToTop?.();
            }
        }
    };
    const ref = useResizeObserver((entity) => {
        if (dom && toBeforeScroll) {
            dom.scrollTo({
                top: dom.scrollHeight - beforeScrollHeight.current,
            });
        }
    });
    return <div ref={(dom) => {
        setDom(dom)
    }}
        onScroll={handleOnScroll}
        className={`w-full h-full disable-scrollbar ${className} ${axis === "Vertical" ? "overflow-y-scroll" : "overflow-x-scroll"}`}>
        <div ref={ref}>
            {children}
        </div>
    </div>
}

export default ScrollView;