import { Dialog, Typography } from "@mui/material";
import CoreBox from "./core-box";
import { useNavigate } from "react-router";

const ServiceDialog = () => {
    const navigate = useNavigate();

    return <CoreBox >
        <div className="flex flex-col gap-2">
            {/* <div className="rounded-lg bg-gradient-to-r from-secondary to-primary flex gap-2 p-2" onClick={()=>{
                navigate();
            }}>
                <img src="/static/images/icons/ask-doctor.png" alt="ask-doctor" className="w-4 h-4" />
                <Typography color="text.disabled">问医生</Typography>
            </div> */}
            <div className="rounded-lg bg-gradient-to-r from-secondary to-primary flex gap-2 p-2" onClick={() => {
                window.location.href = `tel:${4006690769}`;
            }}>
                <img src="/static/images/icons/phone.png" alt="phone" className="w-4 h-4" />
                <Typography  className="text-white">电话问诊</Typography>
            </div>
            <div className="rounded-lg bg-gradient-to-r from-secondary to-primary flex gap-2 p-2" onClick={() => {
                navigate("/service");
            }}>
                <img src="/static/images/icons/service.png" alt="service" className="w-4 h-4" />
                <Typography  className="text-white">在线客服</Typography>
            </div>
        </div>
    </CoreBox>
}

export default ServiceDialog;