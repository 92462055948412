import { Box, Typography } from "@mui/material";
import { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ProductListItem from "./product-list-item";
import { getRecommendProduct } from "@/core/request/product";
import axios from "axios";

export interface ProductListRefInterface {
    applyNextPage(): void;
}

const ProductList = forwardRef((props: any, ref: any) => {
    const [productList, setProductList] = useState<Array<SimpleProductModel>>([]);
    const fetchProductList = async () => {
        const res = await getRecommendProduct();
        if (res) {
            setProductList([...productList, ...res]);
        }
    }
    useImperativeHandle(ref, () => ({
        applyNextPage() {
            handleNextPage();
        }
    }));
    const handleNextPage = () => {
        fetchProductList();
    }
    useEffect(() => {
        handleNextPage();
    }, []);

    return <div>
        <div className="grid grid-cols-2 gap-2 justify-center mt-2">
            {
                productList.map((_, ndx) => {
                    return <ProductListItem key={_.ProductCode} item={_}></ProductListItem>;
                })
            }
        </div>
        <div className="flex justify-center w-full mt-2 items-center">
            <img src="/static/images/loading/loadingimg" alt="loading" className="w-[2rem] h-[2rem]" />
            <Typography color="text.primary">加载中...</Typography>
        </div>
    </div>
})

export default ProductList;