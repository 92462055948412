import MuiTheme, {
  darkPalette,
  getMuiTheme,
  lightPalette,
} from "@/config/themes/mui-thme";
import { ThemeOptions, createTheme } from "@mui/material";

function createAppTheme(mode: ThemeType): ThemeOptions {
  const palette = mode === "dark" ? darkPalette : lightPalette;

  return createTheme({
    ...getMuiTheme(palette),
    palette,
  });
}

export { createAppTheme };
