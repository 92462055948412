import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
    {
        path: "/search",
        element: lazyLoad(React.lazy(() => import("@/views/search"))),
        meta: {
            requiresAuth: false,
            title: "搜索",
        },
    },
    {
        path: "/search/result",
        element: lazyLoad(React.lazy(() => import("@/views/search/search-result"))),
        meta: {
            requiresAuth: false,
            title: "搜索结果",
        },
    },
] as Array<RouteObject>;
