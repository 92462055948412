import { DefaultResponseModel } from "@/types/http";
import http, { CONTENT_TYPE_MULTIPART_FORM_DATA } from "../utils/http-client";

/**
 * 两元包邮购买
 * @param productCode
 * @param id
 * @returns
 */
export const getProductActiveOrderInfo = (
  productCode?: number,
  id?: number
) => {
  return http.post<DefaultResponseModel>({
    url: "/BuyCartController/ProductActiveOrderInfo",
    payload: {
      productCode: productCode,
      buyNowType: 99,
      combinationActiveConfigId: id,
      sourceUrl: "app.ivypha.com",
      sourceType: 3,
    },
  });
};

/**
 * 两元包邮数据列表
 * @returns
 */
export const getCombinationActiveConfigList = () => {
  return http.post<Array<PreferentialModel>>({
    url: "/Product/GetCombinationActiveConfigList",
    config: {
      headers: {
        "Content-Type": CONTENT_TYPE_MULTIPART_FORM_DATA,
      },
    },
  });
};
