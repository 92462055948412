import { Box, Container, Paper } from "@mui/material";
import AppNavigation from "../bottom-navigation"
import { Outlet } from "react-router";

const AppLayout: React.FC = () => {
    return <>
        <Box  sx={{
            boxSizing: "border-box",
            height: '100vh',
            minHeight:'100vh'
        }}>
            <Outlet></Outlet>
            {/* {children} */}
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,zIndex:999, }}>
                <AppNavigation />
            </Paper>
        </Box>
    </>
}

export default AppLayout;