import { Typography } from "@mui/material";
import { FC } from "react";

const DrugImgMask: FC<{
    children: React.ReactNode,
    isMask: boolean
}> = ({ children, isMask }) => {
    return <div className="relative w-full h-full bg-[rgba(255,255,255,.3)] aspect-square">
        {isMask && <div className="absolute text-center w-full h-full top-0 left-0 backdrop-blur-sm flex items-center justify-center overflow-hidden">
            <Typography style={{
                    fontSize:'50%'
                }} color="text.primary">
                <div >凭处方在药师指导下购买和使用</div>
            </Typography>
        </div>}
        {children}
    </div>
}

export default DrugImgMask;