import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Card, CardMedia, Chip, Typography } from '@mui/material';
import { getCombinationActiveConfigList } from '@/core/request/preferential';
import 'swiper/css';
import CoreBox from '@/common/components/widgets/core-box';
const HomeAdvertise: React.FC = () => {
    const [freeshippingList, setFreeshippingList] = useState<any>(null);
    const [imgList] = useState([
        {
            img: 'https://image.ivypha.com/s/upload/prodimage/202009/725926/725926_202009012011464649!320x320.jpg',
            ProductCode: '725926',
        },
        {
            img: 'https://image.ivypha.com/s/upload/prodimage/202009/725896/725896_202009012022486991!320x320.jpg',
            ProductCode: '725896',
        },
        {
            img: 'https://image.ivypha.com/s/upload/prodimage/202009/725906/725906_202009012014378949!320x320.jpg',
            ProductCode: '725906',
        },
        {
            img: 'https://image.ivypha.com/s/upload/prodimage/202009/725889/725889_202009012017442399!320x320.jpg',
            ProductCode: '725889',
        },
        {
            img: 'https://image.ivypha.com/s/upload/prodimage/202009/725952/725952_202009012004082677!320x320.jpg',
            ProductCode: '725952',
        },
        {
            img: 'https://image.ivypha.com/s/upload/prodimage/202009/725916/725916_202009012013128995!320x320.jpg',
            ProductCode: '725916',
        },
        {
            img: 'https://image.ivypha.com/s/upload/prodimage/202009/725967/725967_202009012002068809!320x320.jpg',
            ProductCode: '725967',
        }
    ]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchTwoMoney();
    }, []);

    const fetchTwoMoney = async () => {
        const data = await getCombinationActiveConfigList();
        setFreeshippingList(data[0]);
    };

    const getTwoImage = () => {
        if (!freeshippingList)
            return "https://image.ivypha.com/upload/prodimage/202304/755430/755430_202304040953062579.jpg";
        const ran = Math.floor(Math.random() * freeshippingList.CombinationActiveProductDtoList.length);
        return freeshippingList.CombinationActiveProductDtoList[ran].ProductImageUrl;
    };

    const handleToAllProduct = () => {
        navigate('/category', {
            replace: true,
        });
    };

    const handleToViewToMoney = () => {
        navigate('/preferential');
    };

    const handleToProductDetail = (productCode: string) => {
        navigate(`/product-detail`,{
            state:{
                productCode,
            }
        });
    };

    return (
        <div className="grid grid-cols-3 gap-2 py-2 box-border">
            <CoreBox onClick={handleToAllProduct} className="rounded-lg bg-gradient-to-b from-primary to-secondary">
                <div className="text-center items-center justify-center flex mb-2" >
                    <Typography variant="body1" className="text-white">
                        好药专区
                    </Typography>
                    <Chip label={"实惠"} color="error" size='small' className="pl-2 scale-75"></Chip>
                </div>
                <div className="bg-white rounded-md p-4">
                    <img src={getTwoImage()} alt="好药低价" />
                </div>
                <div className="text-center mt-4">
                    <Typography variant="body1" className="text-white">
                        好药低价
                    </Typography>
                </div>
            </CoreBox>

            <CoreBox className="rounded-lg bg-gradient-to-b from-primary to-secondary" onClick={handleToViewToMoney}>
                <div className="text-center items-center justify-center flex mb-2" >
                    <Typography variant="body1" className="text-white">
                        两元专区
                    </Typography>
                    <Chip label={"便宜"} color="error" size='small' className="pl-2 scale-75"></Chip>
                </div>
                <div className="bg-white rounded-md flex flex-col items-center justify-center p-4">
                    <img src={getTwoImage()} alt="两元专区" />
                    <div>
                        <Typography variant="body2" color="error">
                            两元一件
                        </Typography>
                        <Typography variant="body2">
                            全场包邮
                        </Typography>
                    </div>
                    {/* <div className="flex justify-between items-center mt-4 w-full">
                        <img src={getTwoImage()} alt="两元专区" />
                        <div className="ml-4">
                            <Typography variant="body2" color="error">
                                两元一件
                            </Typography>
                            <Typography variant="body2">
                                全场包邮
                            </Typography>
                        </div>
                    </div> */}
                </div>
            </CoreBox>

            <CoreBox className="rounded-lg bg-gradient-to-b from-danger to-danger flex-col flex">
                <div className="text-center items-center justify-center flex mb-2" onClick={handleToAllProduct}>
                    <Typography variant="body1" className="text-white">
                        中智草精华
                    </Typography>
                    <Chip label={"健康"} color="error" size='small' className="pl-2 scale-75"></Chip>
                </div>
                <div className="bg-white rounded-md flex-1">
                    <Swiper
                        className="w-full h-full"
                        autoplay={{ delay: 3000 }}
                        loop
                    >
                        {imgList.map((item, index) => (
                            <SwiperSlide key={index} onClick={() => handleToProductDetail(item.ProductCode)}>
                                <CardMedia
                                    component="img"
                                    image={item.img}
                                    className="h-full"
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </CoreBox>
        </div>
    );
};

export default HomeAdvertise;
