import React from "react";
import lazyLoad from "../utils/lazy-load";

/**
 * 健康档案
 */
export default [
    {
        path: "/health-archived",
        element: lazyLoad(React.lazy(() => import("@/views/health-archived"))),
        meta: {
            requiresAuth: true,
            title: "健康档案",
        },
    },
    {
        path: "/health-archived-detail",
        element: lazyLoad(React.lazy(() => import("@/views/health-archived-detail"))),
        meta: {
            requiresAuth: true,
            title: "病历详情",
        },
    },
    {
        path: "/health-archived-users",
        element: lazyLoad(React.lazy(() => import("@/views/health-archived-users"))),
        meta: {
            requiresAuth: true,
            title: "成员管理",
        },
    },
    {
        path: "/health-archived-add-user",
        element: lazyLoad(React.lazy(() => import("@/views/health-archived-add-user"))),
        meta: {
            requiresAuth: true,
            title: "新增成员",
        },
    },
] as Array<RouteObject>;
