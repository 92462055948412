import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
    {
        path: "/ask-doctor",
        element: lazyLoad(React.lazy(() => import("@/views/ask-doctor"))),
        meta: {
            requiresAuth: true,
            title: "提问记录",
        },
    },
    {
        path: "/ask-doctor-create",
        element: lazyLoad(React.lazy(() => import("@/views/ask-doctor-create"))),
        meta: {
            requiresAuth: true,
            title: "发起提问",
        },
    },
    {
        path: "/ask-doctor-detail",
        element: lazyLoad(React.lazy(() => import("@/views/ask-doctor-detail"))),
        meta: {
            requiresAuth: true,
            title: "提问详情",
        },
    },
  
] as Array<RouteObject>;
