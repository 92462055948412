import { useRoutes, RouteObject as ReactRouteObject } from "react-router-dom";
import routesIndex from "./routes";
import { FC } from "react";


const mergeRoutes = (): Array<RouteObject> => {
    const modules = require.context("./modules", true, /\.\/.*\.tsx$/);
    const routeMap: Array<RouteObject> = [...routesIndex];
    modules.keys().forEach(_ => {
        const module = modules(_).default;
        if (Array.isArray(module)) {
            routeMap.push(...module)
        } else {
            routeMap.push(module)
        }
    });
    return routeMap;
}

export const routesItems = mergeRoutes();


const AppRouter: FC = () => {
    const routeMap = routesItems;
    const routes = useRoutes(routeMap as Array<ReactRouteObject>);
    return routes;
}

export default AppRouter;