import { Box, Skeleton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import { useEffect, useState, useRef } from "react";
import { getBannerList } from "@/core/request/other";
import { useNavigate } from "react-router";
// import HttpClient from "@/core/utils/http-client";



const Banner: React.FC = () => {
    const [images, setImages] = useState<Array<IndexBannerItem>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [placeholderHeight, setPlaceholderHeight] = useState<number | null>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const navigate = useNavigate()
    const fetchBannerList = async () => {
        const res = await getBannerList();
        if (res) {
            console.log(res);
            setImages(res);
        }

    }


    const handleChange = (e: any) => {

    }

    useEffect(() => {
        fetchBannerList();
    }, []);

    useEffect(() => {
        if (imgRef.current) {
            setPlaceholderHeight(imgRef.current.clientHeight);
        }
    }, [imgRef.current?.clientHeight]);

    return (
        <Box sx={{ width: "100%" }} className="rounded-md overflow-hidden my-2">
            {loading && (
                // 占位符，高度根据图片的预期高度自适应
                <Skeleton variant="rectangular" sx={{ width: "100%", height: placeholderHeight }} />
            )}
            <Swiper spaceBetween={50} loop={true} onSlideChange={handleChange} autoplay={true}>
                {images.map(item => (
                    <SwiperSlide key={item.img}>
                        <Box sx={{ width: "100%" }} className="rounded-md" onClick={() => {
                            const url = JSON.parse(item.url);
                            if (url?.type === "special") {
                                navigate(`/special?classCode=${url?.data}&title=好药专区`);
                            }

                        }}>
                            {loading ? (
                                // 占位符，高度根据图片的预期高度自适应
                                <Skeleton variant="rectangular" sx={{ width: "100%", height: placeholderHeight }} />
                            ) : (
                                // 图片加载完成后显示实际的图片
                                <img ref={imgRef} onLoad={() => setPlaceholderHeight(imgRef.current?.clientHeight as number)} src={item.img} alt={item.img} style={{ width: "100%", height: "auto" }} />
                            )}
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
}

export default Banner;
