//持久化工具
export const storageUtils = {
  getItem(key: string) {
    return localStorage.getItem(key);
  },
  addItem(key: string, value: string) {
    localStorage.setItem(key, value);
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
  clean() {
    localStorage.clear();
  },
};

/**
 * 格式化金额单位
 */
export const formatPrice = (value: number) => {
  return (value * 0.01).toFixed(2);
};

/**
 * 防抖
 */
export const deBounce = (callback: VoidFunction, delay: number) => {
  let timer: any;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      callback.apply(null);
      timer = null;
    }, delay);
  };
};

/**
 * 节流
 */
export const throttle = (callback: VoidFunction, delay: number) => {
  let preCall: number = +new Date();
  return function () {
    const currentTime: number = +new Date();
    if (currentTime - preCall >= delay) {
      preCall = currentTime;
      callback.apply(null);
    }
  };
};

/**
 * 通过类型判断是否处方药
 * @param prescriptionType 处方类型
 * @returns
 */
export const checkIsRxByPrescriptionType = (
  prescriptionType: string | number
): boolean => {
  const isRx: boolean =
    Number(prescriptionType) === 4 || Number(prescriptionType) === 5;
  return isRx;
};

/**
 * 打开常青藤网上药店APP
 */
export const handleOpenIvyphaAPP = () => {
  console.log("打开",process.env)
  window.open(process.env.REACT_APP_OPEN_IVYPHA_SCHEME);
  setTimeout(() => {
    window.open(
      process.env.REACT_APP_DOWNLOAD_IVYPHA_URL,
    );
  }, 1000);
};
