import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
    {
        path: "/orders",
        element: lazyLoad(React.lazy(() => import("@/views/orders"))),
        meta: {
            requiresAuth: true,
            title: "我的订单",
        },
    },
    {
        path: "/order-detail",
        element: lazyLoad(React.lazy(() => import("@/views/order-detail"))),
        meta: {
            requiresAuth: true,
            title: "订单详情",
        },
    },
    {
        path: "/order-tracking",
        element: lazyLoad(React.lazy(() => import("@/views/order-tracking"))),
        meta: {
            requiresAuth: true,
            title: "订单跟踪",
        },
    },
    {
        path: "/refund",
        element: lazyLoad(React.lazy(() => import("@/views/refund"))),
        meta: {
            requiresAuth: true,
            title: "退款/售后",
        },
    },
] as Array<RouteObject>;
