import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
    {
        path: "/confirm-order",
        element: lazyLoad(React.lazy(() => import("@/views/confirm-order"))),
        meta: {
            requiresAuth: true,
            title: "确认订单",
        },
    },
    {
        path: "/prescription",
        element: lazyLoad(React.lazy(() => import("@/views/prescription"))),
        meta: {
            requiresAuth: true,
            title: "处方信息",
        },
        
    },
    {
        path: "/add-prescription",
        element: lazyLoad(React.lazy(() => import("@/views/add-prescription"))),
        meta: {
            requiresAuth: true,
            title: "添加就诊人",
        },
        
    }
] as Array<RouteObject>;
