import http from "../utils/http-client";

export const getBannerList = () => {
  return http.get<Array<IndexBannerItem>>({
    url: "/api/advertis/28",
    config: {
      baseURL: "https://new.ivypha.com",
    },
    disableLoading: true,
  });
};
