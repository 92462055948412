import { Box, Button, Switch, Typography, } from "@mui/material";
import { BasePage } from "../base"
import Banner from "./components/banner";
import HomeNav from "./components/nav";
import ProductList, { ProductListRefInterface } from "@/common/components/product-list/product-list";
import { FC, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { AppContext } from "@/core/contexts";
import { SearchBox } from "../search";
import { useNavigate } from "react-router";
import http from "@/core/utils/http-client";
import ScrollView from "@/common/components/scroll-view/scrill-view";
import HomeAdvertise from "./components/advertise";
import DoctorConsultation from "./components/doctor-consultation";
import HomeBrand from "./components/brand";
import { toastError } from "@/core/utils/notification";
import { handleOpenIvyphaAPP } from "@/core/utils";


const Index = () => {
    const productListRef = useRef<MayUndefined<ProductListRefInterface>>(null);
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const bgRef = useRef<MayUndefined<BgInterface>>(null);

    const handleToSearch = () => {
        navigate("/search");
    }



    useEffect(() => {

    }, []);

    const handlOnScrollToBottom = () => {
        productListRef?.current?.applyNextPage?.();
    }

    return <BasePage disableAppBar>
        <Box pb={8} sx={{
            width: 100 + '%',
            height: 100 + '%',
            overflow: 'hidden',
            position: 'relative',
        }}>

            <Bg ref={bgRef} />
            <ScrollView className="relative w-full h-full top-0" onScroll={(delta) => {
                const maxOffset = 600;
                if (delta < maxOffset) {
                    const par = delta / maxOffset;
                    if (bgRef) {
                        bgRef.current?.handleChangeOpacity(1 - par);
                    }
                } else {
                    if (bgRef) {
                        bgRef.current?.handleChangeOpacity(0);
                    }
                }

            }} onScrollToBottom={handlOnScrollToBottom}>
                <div className="py-2 bg-primary sticky top-0 px-2 z-10 w-full">
                    {/* ivypha://app.ivypha.com:8888/homepage */}
                    <div className="flex w-full justify-between items-center py-2">
                        <div className="flex gap-2 items-center">
                            <img className="w-[2rem] h-[2rem] bg-white rounded-lg overflow-hidden" src={`${process.env.PUBLIC_URL}${"/static/images/login/login.jpg"}`} alt="logo" />
                            <Typography variant="body1" className="text-white">打开常青藤网上药店APP</Typography>
                        </div>
                        <Button onClick={
                            () => {
                                handleOpenIvyphaAPP();
                            }
                        } variant="outlined" sx={{
                            borderRadius: "10rem"
                        }} color="secondary">
                            <span className="text-white">打开</span>
                        </Button>
                    </div>

                    <div onClick={handleToSearch}>
                        <SearchBox />
                    </div>
                </div>
                <div className="px-2 pt">
                    <HomeNav />
                    <Banner></Banner>
                    <DoctorConsultation />
                    <HomeAdvertise />
                    <HomeBrand />
                    <div className="py-4 flex gap-2 w-full justify-center items-center">
                        <img src="/static/images/other/ip.png" alt="ip" className="w-[1.5rem] h-[1.5rem]" />
                        <Typography variant="h6" color="text.primary">更多好药</Typography>
                    </div>
                    <ProductList ref={productListRef}></ProductList>
                </div>
            </ScrollView>
        </Box>
    </BasePage>
}


interface BgInterface {
    handleChangeOpacity(opacity: number): void;
    ref: any
}

const Bg = forwardRef((props: any, ref: any) => {
    const [bgOpacity, setBgOpacity] = useState<number>(1);

    useImperativeHandle(ref, () => ({
        handleChangeOpacity(opacity: number) {
            if (bgOpacity === 0 && opacity===0) return;
            changeOpacity(opacity);
        }
    }))
    const changeOpacity = (opacity: number) => {
        setBgOpacity(opacity);
    }
    return <div className="overflow-hidden absolute z-0 w-full h-[150vw]">
        <div className={`w-[150vw] h-[150vw] left-[-25vw] top-[-50vw] bg-primary rounded-full absolute z-0`} style={{
            opacity: bgOpacity
        }}></div>
    </div>
});
export default Index;