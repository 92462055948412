import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
    {
        path: "/preferential",
        element: lazyLoad(React.lazy(() => import("@/views/preferential"))),
        meta: {
            requiresAuth: true,
            title: "特惠好药",
        },
    },
    {
        path: "/special",
        element: lazyLoad(React.lazy(() => import("@/views/special"))),
        meta: {
            requiresAuth: true,
            title: "好药专区",
        },
    },
    {
        path: "/sign",
        element: lazyLoad(React.lazy(() => import("@/views/sign"))),
        meta: {
            requiresAuth: true,
            title: "签到中心",
        },
    },{
        path: "/free-product",
        element: lazyLoad(React.lazy(() => import("@/views/free-product"))),
        meta: {
            requiresAuth: false,
            title: "免费赠药",
        },
    },{
        path: "/accreditation",
        element: lazyLoad(React.lazy(() => import("@/views/accreditation"))),
        meta: {
            requiresAuth: false,
            title: "资质认证",
        },
    }
] as Array<RouteObject>;
