import { Box, CircularProgress } from "@mui/material";
import React, { Suspense } from "react";

// const lazyLoad = (path: string) => {
//   const Component = React.lazy(() => import(path));
//   return <Suspense fallback={
//     <Box>
//       <CircularProgress />
//     </Box>
//   }>
//     <Component />
//   </Suspense>;
// };

// export default lazyLoad;

/**
 * @description 路由懒加载
 * @param {Element} Comp 需要访问的组件
 * @returns element
 */
const lazyLoad = (Comp: any): React.ReactNode => {
  return (
    <Suspense
      fallback={
        <div className="w-[100vw] h-[100vh] flex justify-center items-center">
          <CircularProgress />
        </div>
      }
    >
      <Comp />
    </Suspense>
  );
};

export default lazyLoad;

