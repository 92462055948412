import { ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import AppRouter from './core/routes';
import AuthRouter from './core/routes/utils/auth';
import MuiTheme from './config/themes/mui-thme';
import { AppContext } from './core/contexts';
import { useState } from 'react';
import { createAppTheme } from './core/utils/theme';
import { ErrorBoundary } from "react-error-boundary";
import FallbackError from './views/error/fallback-error';
import WithGlobalComponents from './common/components/withGlobalComponents/withGlobalComponents';
import "rc-cascader/assets/index.less";
import eruda from "eruda";
function App() {
  const [mode, setMode] = useState<ThemeType>("light");
  const context: AppContextValue = {
    changeTheme: (mode: ThemeType) => {
      setMode(mode);
    },
    currentTheme: mode,
  };

  if (process.env?.NODE_ENV === "development") {
    eruda.init();
  }

  return (
    <ErrorBoundary fallback={<FallbackError />}>
      <AppContext.Provider value={context}>
        <ThemeProvider theme={createAppTheme(mode)}>
          <AuthRouter>
            <WithGlobalComponents>
              <AppRouter></AppRouter>
            </WithGlobalComponents>
          </AuthRouter>
        </ThemeProvider>
      </AppContext.Provider>
    </ErrorBoundary>

  );
}

export default App;
