import { makeObservable, observable, action } from "mobx";

class ScreenFullLoading {
  public isLoading: boolean = false;
  private callback: VoidFunction=()=>{};
  constructor() {
    makeObservable(this, {
      isLoading: observable,
      setLoading: action,
    });
  }
  setLoading(value: boolean) {
    this.isLoading = value;
    this.callback?.();
  }
  subScribe(callback: VoidFunction) {
    this.callback = callback;
  }
}

const screenFullLoading = new ScreenFullLoading();
export default screenFullLoading;
