import { Navigate } from "react-router-dom";

import lazyLoad from "./utils/lazy-load";
import React from "react";
// import { RouteObject } from "@/types";
const routesIndex: Array<RouteObject> = [
    {
        path: '/',
        element: <Navigate to="/index" replace={true} />,
    },
    {
        path: "*",
        element: lazyLoad(React.lazy(() => import("@/views/error/404"))),
    },{
        path: "*/ErrorRedirect",
        element: lazyLoad(React.lazy(() => import("@/views/error/404"))),
    }
];
export default routesIndex;