import React from "react";
import lazyLoad from "../utils/lazy-load";

const product_detail:RouteObject= {
    element: lazyLoad(React.lazy(() => import("@/views/product-detail"))),
    path: "/product-detail",
    meta: {
        title: "商品详情"
    }
};

export default product_detail;