import { Box } from "@mui/material";
import { FC, ReactElement } from "react";

const CoreBox: FC<{
    children?: Array<ReactElement> | ReactElement,
    className?: string,
    onClick?: () => void
}> = ({ children = <span></span>, className = 'rounded-lg', onClick }) => {
    return <Box onClick={() => { onClick?.() }} p={1} className={className} bgcolor='background.default'>
        {children}
    </Box>
}

export default CoreBox;