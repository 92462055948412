import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Button, Grid } from '@mui/material';
import CoreBox from '@/common/components/widgets/core-box';

const HomeBrand = () => {
    const navigate = useNavigate();
    const [imgList] = useState([
        { img: 'https://image.ivypha.com/s/upload/prodimage/202009/725926/725926_202009012011464649!320x320.jpg', ProductCode: '725926' },
        { img: 'https://image.ivypha.com/s/upload/prodimage/202009/725896/725896_202009012022486991!320x320.jpg', ProductCode: '725896' },
        { img: 'https://image.ivypha.com/s/upload/prodimage/202009/725906/725906_202009012014378949!320x320.jpg', ProductCode: '725906' },
        { img: 'https://image.ivypha.com/s/upload/prodimage/202009/725889/725889_202009012017442399!320x320.jpg', ProductCode: '725889' },
        { img: 'https://image.ivypha.com/s/upload/prodimage/202009/725952/725952_202009012004082677!320x320.jpg', ProductCode: '725952' },
        { img: 'https://image.ivypha.com/s/upload/prodimage/202009/725916/725916_202009012013128995!320x320.jpg', ProductCode: '725916' },
        { img: 'https://image.ivypha.com/s/upload/prodimage/202009/725967/725967_202009012002068809!320x320.jpg', ProductCode: '725967' },
    ]);
    const [imgList2] = useState([
        { img: 'https://image.ivypha.com/upload/prodimage/202001/747938/747938_202001071812530914.jpg', log: 'https://new.ivypha.com/static/uploads/2022/9/5//0523320d9c80a35e4133c92b081d1390.png', ProductCode: '747938' },
        { img: 'https://image.ivypha.com/upload/prodimage/202010/750753/750753_202010291534409960.jpg', log: 'https://new.ivypha.com/static/uploads/2022/11/30//c803b5d627edde5936fe2759a44abbfc.jpg', ProductCode: '750753' },
        { img: 'https://image.ivypha.com/s/upload/prodimage/202103/755888/755888_202103271549416152!320x320.jpg', log: 'https://new.ivypha.com/static/uploads/2022/9/5//d79cb49fdc3fc9ce536a23603549058c.png', ProductCode: '755888' },
        { img: 'https://image.ivypha.com/upload/prodimage/202207/759129/759129_202207111446351908.jpg', log: 'https://new.ivypha.com/static/uploads/2022/9/5//a731e196fdc75cbbf6f515103486c282.png', ProductCode: '759129' },
    ]);

    const [imgList3] = useState([
        {
            logo: 'https://new.ivypha.com/static/uploads/2022/9/5//15851ca4471b17704969604ca6a65e62.png',
            children: [
                {
                    img: 'https://image.ivypha.com/s/upload/prodimage/202012/750921/750921_202012181507051505!320x320.jpg',
                    ProductCode: '750921'
                },
                {
                    img: 'https://image.ivypha.com/s/upload/prodimage/202003/748985/748985_202003211028157108!320x320.jpg',
                    ProductCode: '748985'
                }
            ]
        },
        {
            logo: 'https://new.ivypha.com/static/uploads/2022/7/4//71f5a206f9958daf2e22bf730f2b45e7.png',
            children: [
                {
                    img: 'https://image.ivypha.com/upload/prodimage/202101/754928/754928_202101291021522457.jpg',
                    ProductCode: '754928'
                },
                {
                    img: 'https://image.ivypha.com/upload/prodimage/202112/755710/755710_202112300939426286.jpg',
                    ProductCode: '755710'
                }
            ]
        }
    ]);

    const handleNavigation = (productCode: string) => {
        navigate(`/product-detail`, {
            state: {
                productCode
            }
        });
    };

    return (
        <div>
            <div className="py-2 flex gap-2 items-center">
                <div className='w-1 h-[1rem] bg-primary rounded-md'></div>
                <Typography variant="h6" color="text.default">品牌好药</Typography>
            </div>
            <div className="flex flex-col items-center w-full">
                <div className="grid grid-cols-2 gap-2 w-full">
                    {
                        imgList3.map(_ => {
                            return <div className='w-full' key={_.logo}>
                                <CoreBox className='w-full rounded-lg'>
                                    <div>
                                        <img src={_.logo} alt='logo' className="w-[6rem]" />
                                        <div className='grid grid-cols-2 gap-2'>
                                            {
                                                _.children.map(_ => {
                                                    return <div key={_.ProductCode} onClick={() => handleNavigation(_.ProductCode)}>
                                                        <img src={_.img} alt="product" className="w-full" />
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </CoreBox>
                            </div>
                        })
                    }
                </div>
                <div className="grid grid-cols-4 gap-2 mt-2">
                    {
                        imgList2.map(_ => {
                            return <div key={_.ProductCode}>
                                <CoreBox>
                                    <div onClick={() => handleNavigation(_.ProductCode)}>
                                        <img src={_.log} alt='logo' className="w-full" />
                                        <img src={_.img} alt="product" className="w-full" />
                                    </div>
                                </CoreBox>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default HomeBrand;
