import { useLoading } from "@/hooks/notification";
import { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { GlobalComponentsContext } from "../contexts";
import screenFullLoading from "./loading";

export const toastInfo = (content: string) => {
  toast.info(content);
}

export const toastError = (content: string) => {
  toast.error(content);
}

export const toastSuccess = (content: string) => {
  toast.success(content);
}


export const showLoading = () => {
  screenFullLoading.setLoading(true);
}

export const hideLoading = () => {
  screenFullLoading.setLoading(false);
}