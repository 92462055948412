import {
  HttpClientInterface,
  HttpClientRequestMethods,
  HttpClientRequestParametersInterface,
  HttpClientRequestConfig,
} from "@/types/http";
import axios, {
  Axios,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import {
  hideLoading,
  showLoading,
  toastError,
  toastSuccess,
} from "./notification";
import { useStorage } from "@/hooks/location-store";
import { storageUtils } from ".";
// import { toastError } from "./notification";

/**
 * "multipart/form-data"
 */
export const CONTENT_TYPE_MULTIPART_FORM_DATA: string = "multipart/form-data";

export const getAuthorizationToken = () => {
  const { getItem } = storageUtils;
  const locationToken = getItem("AuthorizationToken");
  return locationToken;
};

abstract class HttpClient implements HttpClientInterface {
  private instance: AxiosInstance;
  /**
   * 白名单配置
   * 再白名单内部的api不需要token传入
   */
  protected whiteList: Array<string> = [
    "/api/advertis/28",
    "/visitor_login",
    "/2/message",
    "/messages",
    "/uploadimg",
    "/videoinfo",
  ];
  constructor(config: HttpClientRequestConfig = {}, whiteList?: Array<string>) {
    this.instance = axios.create(config);
    //拦截器设置
    this.instance.interceptors.request.use(
      (config) => this.requestInterceptor(config),
      (err: any) => {
        return Promise.reject(err);
      }
    );
    this.instance.interceptors.response.use(
      this.responseInterceptor,
      this.responseError
    );
    this.whiteList = whiteList ?? this.whiteList;
  }

  protected requestInterceptor(
    config: InternalAxiosRequestConfig
  ): InternalAxiosRequestConfig {
    const url = config.url as string;

    //没有在白名单内的api需要token
    const requiresToken: boolean = !this.whiteList.includes(url);

    const token = getAuthorizationToken();
    if (!token) {
      //前往登录页面
    }
    if (requiresToken) {
      config.headers!.Authorization = token;
      config.headers!.Authorization1 = token;
    }
    return config;
  }
  protected responseInterceptor(res: AxiosResponse): AxiosResponse {
    const data = res.data;
    if (!data?.isSuccess) {
      toastError(data?.message);
    }
    return data;
    // if (data?.data) return data?.data;
    // if (data) return data;
    // return data?.data ?? data;
  }
  protected responseError(error: AxiosError): Promise<AxiosError> {
    console.log("请求错误");
    if (error.response) {
      const status = error.response.status;
      let errorMessage = "";
      switch (status) {
        case 400:
          errorMessage = "请求错误";
          break;
        case 401:
          errorMessage = "未授权的访问，请登录";
          break;
        case 403:
          errorMessage = "拒绝访问";
          break;
        case 404:
          errorMessage = "请求的资源不存在";
          break;
        case 500:
          errorMessage = "服务器内部错误";
          break;
        case 502:
          errorMessage = "网关错误";
          break;
        case 504:
          errorMessage = "网关超时";
          break;
        default:
          errorMessage = "发生错误，请稍后重试";
          break;
      }

      console.error(errorMessage); // 输出错误信息
    }
    // 将错误对象继续向下传递
    return Promise.reject(error.response);
  }
  request<T>(
    parameters: HttpClientRequestParametersInterface,
    method: HttpClientRequestMethods = "GET"
  ): Promise<T> {
    const response = new Promise<T>((resolve, reject) => {
      const {
        url,
        payload: data,
        params,
        action,
        config: customConfig,
        unprocessed = false,
        disableLoading = false,
      } = parameters;

      const config: HttpClientRequestConfig = {
        method,
        url,
        data,
        params,
        action,
        ...customConfig,
        // withCredentials: true,
      };
      if (!disableLoading) showLoading();
      this.instance
        .request(config)
        .then((res: AxiosResponse) => {
          let data = res?.data;
          hideLoading();
          if (unprocessed) {
            //不处理直接返回,返回类 [DefaultResponseModel]
            resolve(res as T);
          } else {
            //尝试处理为Model
            //某些数据返回的为string,转换失败直接返回
            // let data=res?.data;
            // if(data?.data)data=data?.data;
            if (typeof data === "object") resolve(data as T);
            try {
              const model: T = JSON.parse(data?.toString());
              resolve(model);
            } catch (error) {
              resolve(data as T);
            }
          }
        })
        .catch((err: AxiosError) => {
          hideLoading();
          // toastError(err?.message || "网络请求错误");
          // resolve(err);
        });
    });
    return response;
  }
  get<T>(parameters: HttpClientRequestParametersInterface): Promise<T> {
    return this.request<T>(parameters, "GET");
  }
  post<T>(parameters: HttpClientRequestParametersInterface): Promise<T> {
    return this.request<T>(parameters, "POST");
  }
}

class SimpleHttpClient extends HttpClient {}

const http = new SimpleHttpClient({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "/api"
      : process.env.REACT_APP_HTTP_BASE_URL,
});

export { SimpleHttpClient };

export default http;
