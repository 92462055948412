
import navigationItems from "@/config/bottom-navigation";
import { BottomNavigation, BottomNavigationAction } from "@mui/material"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

const AppNavigation: React.FC = () => {
    const [value, setValue] = useState(navigationItems[0].value);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const pathName = location.pathname;
        setValue(pathName);
    }, [location]);
    const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
        setValue(newValue);
        navigate(newValue, {
            replace: true,
        });
    }
    return <BottomNavigation showLabels onChange={handleChange} value={value}>
        {
            navigationItems.map(_ => {
                return <BottomNavigationAction key={_.value} label={_.label} icon={_.icon} value={_.value} />;
            })
        }
    </BottomNavigation>
}

export default AppNavigation;