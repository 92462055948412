import { createContext } from "react";

export const AppContext =
  createContext<MayUndefined<AppContextValue>>(undefined);

export const GlobalComponentsContext =
  createContext<MayUndefined<GlobalComponentsContextValue>>(undefined);

export const AddressContext = createContext<AddressContextValue>({
  manage: false,
});
