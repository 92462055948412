import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
  {
    path: "/login",
    element: lazyLoad(React.lazy(() => import("@/views/login"))),
    meta: {
      requiresAuth: false,
      title: "登录",
    },
  },
  {
    path: "/login/accept-code",
    element: lazyLoad(React.lazy(() => import("@/views/login/accept-code"))),
    meta: {
      requiresAuth: false,
      title: "接收验证码",
    },
  },
  {
    path: "/login/password-login",
    element: lazyLoad(React.lazy(() => import("@/views/login/password-login"))),
    meta: {
      requiresAuth: false,
      title: "密码登录",
    },
  }
] as Array<RouteObject>;
