import lazyLoad from "../utils/lazy-load";
import React from "react";

const settins: Array<RouteObject> = [
    {
        element: lazyLoad(React.lazy(() => import("@/views/setting"))),
        path: "/setting",
        meta: {
            title: "商品详情"
        }
    }
]

export default settins;