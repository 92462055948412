// 静态数据
const indexNavItems: Array<Partial<IndexNavItem>> = [
  {
    src: "/static/images/special/message.png",
    label: "咨询药师",
    id: 1,
    classCode: "3333",
    path: "/service",
  },
  {
    src: "/static/images/special/yao.png",
    label: "特惠好药",
    id: 2,
    classCode: "250",
    path: "/preferential",
  },
  {
    src: "/static/images/special/nanke.png",
    label: "男科用药",
    id: 3,
    classCode: "0111",
    path: "/special?classCode=0111&title=男科用药",
  },
  {
    src: "/static/images/special/woman.png",
    label: "妇科用药",
    id: 4,
    classCode: "0110",
    path: "/special?classCode=0110&title=妇科用药",
  },
  {
    src: "/static/images/special/child.png",
    label: "儿科用药",
    id: 5,
    classCode: "0120",
    path: "/special?classCode=0120&title=儿科用药",
  },
  {
    src: "/static/images/special/skin.png",
    label: "皮肤用药",
    id: 6,
    classCode: "0114",
    path: "/special?classCode=0114&title=皮肤用药",
  },
  // {
  //   src: "/static/images/sign/sign.gif",
  //   label: "签到中心",
  //   id: 6,
  //   classCode: "10001",
  //   path: "/sign",
  // },
  {
    src: "/static/images/zixun/zixun.png",
    label: "健康资讯",
    id: 6,
    // classCode: "10002",
    path: "https://new.ivypha.com/",
    isUrl: true,
  },
  {
    src: "/static/images/special/heart.png",
    label: "心脑血管",
    id: 6,
    classCode: "0122",
    path: "/special?classCode=0122&title=心脑血管",
  },
  {
    src: "/static/images/special/liverandgall.png",
    label: "肝胆用药",
    id: 7,
    classCode: "0109",
    path: "/special?classCode=0109&title=肝胆用药",
  },
  {
    src: "/static/images/special/urinarytract.png",
    label: "呼吸内科",
    id: 9,
    classCode: "0103",
    path: "/special?classCode=0103&title=呼吸内科",
  },
  {
    src: "/static/images/special/stomach.png",
    label: "肠胃用药",
    id: 5,
    classCode: "0108",
    path: "/special?classCode=0108&title=肠胃用药",
  },
  {
    src: "/static/images/special/nerve.png",
    label: "神经科药",
    id: 5,
    classCode: "0126",
    path: "/special?classCode=0126&title=神经科药",
  },
  {
    src: "/static/images/special/heart.png",
    label: "心脑血管",
    id: 5,
    classCode: "0122",
    path: "/special?classCode=0122&title=心脑血管",
  },
  {
    src: "/static/images/special/zizhi.png",
    label: "资质认证",
    id: 6,
    classCode: "10002",
    path: "/accreditation",
  },
  
];

export default indexNavItems;
