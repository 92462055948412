import { PaletteOptions, ThemeOptions } from "@mui/material";
/**
 * ## 颜色使用规范
 * 
  * ### 主要颜色 (primary):
      用于突出显示重要的元素，如主要按钮、链接、图标等。
      可以在按钮、链接和图标等元素中使用。
      例如，您可以将主要按钮的背景色设置为 primary.main，以突出显示按钮。
    ### 次要颜色 (secondary):

      用于辅助或次要元素，如次要按钮、文本、图标等。
      可以在辅助按钮、次要文本和图标等元素中使用。
      例如，您可以将次要按钮的背景色设置为 secondary.main，以区分它们与主要按钮。
    ### 背景颜色 (background):

      用于容器、卡片、对话框等背景元素。
      可以在整个应用程序的背景中使用。
      例如，您可以将应用程序的整体背景色设置为 background.default。
    ### 文本颜色 (text):

      用于文本内容，以确保良好的可读性。
      可以在标题、段落、按钮文本等文本内容中使用。
      例如，您可以将标题文本的颜色设置为 text.primary，以确保它在主题背景上有良好的对比度。
 */
const alertPalette: PaletteOptions = {
  error: {
    main: "#F56C6C",
  },
  warning: {
    main: "#E6A23C",
  },
  info: {
    main: "#909399",
  },
  success: {
    main: "#67C23A",
  },
};
const lightPalette: PaletteOptions = {
  primary: {
    main: "#00be46",
    light: "#95d475",
    dark: "#529b2e",
  },
  secondary: {
    main: "#F5F7FA",
    light: "#F5F7FA",
    dark: "#606266",
  },
  background: {
    default: "#ffffff",
    paper:"#f8f8f8"
  },
  text: {
    primary: "#303133",
    secondary: "#909399",
    disabled:"#e1e1e1"
  },
  mode: "light",
};
const darkPalette: PaletteOptions = {
  primary: {
    main: "#00be46",
    light: "#95d475",
    dark: "#529b2e",
  },
  secondary: {
    main: "#606266",
    light: "#606266",
    dark: "#606266",
  },
  background: {
    default: "#000000",
    paper:"#1f1f1f"
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#909399",
    disabled:"#e1e1e1"
  },
  mode: "dark",
};

const MuiTheme: ThemeOptions = {
  typography: {
    fontFamily: "MiSans-Regular",
    fontSize: 11,
  },
  ...alertPalette,
  components: {
    MuiBottomNavigationAction: {
      defaultProps: {
        color: "#ffffff",
      },
    },
  },
};

const getMuiTheme = (palette: ThemeOptions["palette"]): ThemeOptions => {
  return {
    typography: {
      fontFamily: "MiSans-Regular",
      fontSize: 11,
    },
    ...alertPalette,
    components: {
      MuiBottomNavigationAction: {
        defaultProps: {
          color: "#ffffff",
        },
      },
      MuiCheckbox:{
        defaultProps:{
          sx(theme) {
              return {
                color:theme.palette.primary.main
              }
          },
        }
      },
      MuiFormControlLabel: {
        defaultProps: {
          sx(theme) {
            return {
              color: theme.palette.text.primary,
              marginLeft: "0px",
            };
          },
        },
      },
    },
  };
};

export default MuiTheme;
export { darkPalette, lightPalette, alertPalette, getMuiTheme };
