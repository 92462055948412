import Index from "@/views/index";
import AppLayout from "@/views/layout/layout";
import lazyLoad from "../utils/lazy-load";
import React from "react";

export default [
  {
    element: <AppLayout />,
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "/index",
        element: <Index />,
        meta: {
          title: "首页",
          keepAlive: true,
        },
      },
    ],
  },
  {
    element: <AppLayout />,
    children: [
      {
        path: "/me",
        element: lazyLoad(React.lazy(() => import("@/views/me"))),
        meta: {
          title: "我的",
          keepAlive: true,
          requiresAuth: true,
          appBarDisabled: true,
        },
      },
    ],
  },
  {
    element: <AppLayout />,
    children: [
      {
        path: "/category",
        element: lazyLoad(React.lazy(() => import("@/views/category"))),
        meta: {
          title: "分类",
          keepAlive: true,
        },
      },
    ],
  },
  {
    element: <AppLayout />,
    children: [
      {
        path: "/shopping-cart",
        element: lazyLoad(React.lazy(() => import("@/views/shopping-cart"))),
        meta: {
          title: "购物车",
          keepAlive: true,
          requiresAuth: true,
        },
      },
    ],
  },
] as RouteObject[];
