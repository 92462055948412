import http, { CONTENT_TYPE_MULTIPART_FORM_DATA } from "../utils/http-client";

export const getRecommendProduct = () => {
  return http.post<Array<SimpleProductModel>>({
    url: "/Product/GetRecommendProduct",
    payload: {
      sourceType: 3,
    },
    disableLoading:true,
    config: {
      headers: {
        "Content-Type": CONTENT_TYPE_MULTIPART_FORM_DATA,
      },
    },
  });
};


