import { getRecommendProduct } from "@/core/request/product";
import { formatPrice } from "@/core/utils";
import { Card, Box, Skeleton, Typography } from "@mui/material";
import axios, { Axios } from "axios";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CoreBox from "../widgets/core-box";
import DrugImgMask from "../widgets/drug-img-mask";

const ProductListItem: FC<{
    item: IViewItem<Readonly<SimpleProductModel>>,
}> = ({ item }) => {

    const navigate = useNavigate();

    const handleToProductDetail = () => {
        navigate("/product-detail", {
            state: {
                productCode: item.ProductCode,
            }
        });
    }
    // min-w-[184px] min-h-[184px]
    return <Card elevation={0} onClick={handleToProductDetail}>
        <CoreBox>
            <Box className="flex flex-col justify-center p-2">
                <DrugImgMask isMask={false}>
                    <img src={process.env.REACT_APP_IMAGE_BASE_URL+item.ProductImageUrl} alt={item.ProductImageUrl} className="w-full" />
                </DrugImgMask>
                {/* <Skeleton variant="rectangular" width="100%" height="100%" className="aspect-square"></Skeleton> */}
                <Typography component={"h6"} variant="h6" color="text.main" className="mt-2">
                    {item.ProductName}
                </Typography>
                <Typography component={"p"} variant="body2" color="text.secondary" className="mt-2 line-clamp-2">
                    {item.Introduction}
                </Typography>
                <Typography component={"p"} variant="body1" color="error" className="py-2 line-clamp-2">
                    ￥{formatPrice(item.OurPrice)}
                </Typography>
            </Box>
        </CoreBox>
    </Card>
}

export default ProductListItem;