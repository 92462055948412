/**
 * 该组件由AI生成
 * 
 * 
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardMedia, Typography, Button, Dialog } from '@mui/material';
import ServiceDialog from '@/common/components/widgets/service-dialog';
import CoreBox from '@/common/components/widgets/core-box';

const DoctorConsultation: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenServiceDialog = () => {
    setOpen(true);
  };

  const handleAskDoctor = () => {
    handleOpenServiceDialog();
  };

  const phone = (p: number) => {
    handleOpenServiceDialog();
    // Uncomment below to enable phone call functionality if supported
    // window.location.href = `tel:${p}`;
  };

  return (
    <div className="grid grid-cols-2 gap-2  w-full">
      <CoreBox
        className="flex flex-row h-12 items-center rounded-lg bg-gradient-to-b from-secondary to-primary py-2"
        onClick={handleAskDoctor}
      >
        <img src="/static/images/home/online.png" alt="online" className="w-10 h-10" />
        <div className="flex flex-col pl-2 justify-center">
          <div className="flex flex-row items-center">
            <Typography variant="h6" className="font-size-32 font-weight mr-1 text-white">在线问诊</Typography>
          </div>
          <div>
            <div className="flex flex-row items-center">
              <img src="https://new.ivypha.com/static/uploads/2021/09/29/fbb77c3f.jpg" alt="1" className='w-3 h-3 rounded-full overflow-hidden' />
              <img src="https://new.ivypha.com/static/uploads/2021/09/29/fbb77c3f.jpg" alt="1" className='w-3 h-3 rounded-full overflow-hidden' />
              <Typography className="text-white text-sm" >正在咨询...</Typography>
            </div>
          </div>
        </div>
      </CoreBox>

      <CoreBox
        className="flex flex-row h-12 items-center rounded-lg bg-gradient-to-b from-[#7ed0fe] to-[#46a1d6] py-2"
        onClick={handleOpenServiceDialog}
      >
        <img src="/static/images/home/tell.png" alt="online" className="w-10 h-10" />
        <div className="flex flex-col pl-2">
          <Typography variant="h6" className="font-size-32 font-weight mr-1 text-white">电话问诊</Typography>
          <Typography className="text-999999 font-size-18 text-white text-sm">一分钟急速响应</Typography>
        </div>
      </CoreBox>
      <Dialog open={open} onClose={()=>{
        setOpen(false);
      }}>
        <ServiceDialog/>
      </Dialog>
      {/* <ServiceDialog /> */}
    </div>
  );
};

export default DoctorConsultation;
