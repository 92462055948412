import { GlobalComponentsContext } from "@/core/contexts";
import { useContext } from "react"
import ReactDOM from "react-dom";
import { toast } from "react-toastify";

export const useLoading = (): GlobalComponentsContextValue['loading'] => {
    const context = useContext(GlobalComponentsContext);
    if (!context) {
        throw Error("Loading Context Error")
    }
    return context?.loading;
}

export const useDialog = (): GlobalComponentsContextValue['dialog'] => {
    const context = useContext(GlobalComponentsContext);
    if (!context) {
        throw Error("Loading Context Error")
    }
    return context?.dialog;
}